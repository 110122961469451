import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./Pages/Home/homepage";
import NotFound from "./Pages/Notfound/Notfound";
import PrivacyPolicy from "./Pages/PrivacyPolicy/Privacypolicy";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/privacy-policy" element = {<PrivacyPolicy/>}/>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
