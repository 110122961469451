import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import "./homepage.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import sdgImage from "../../assets/sdgs.webp";
import partnershipImage from "../../assets/17-partnership.webp";
import globalCauses from "../../assets/globalcauses.webp";
import storytelling from "../../assets/storytelling.webp";
import csrManagement from "../../assets/csr_management.webp";
import step3 from "../../assets/Step-3.webp";
import bunchfulSpotlightLogo from "../../assets/26.webp";

const Homepage = () => {

    return (
        <div className="homepage">

            {/* Header Section */}
            <Header />

            {/* Hero Section */}
            <div className="hero-hook d-flex align-items-center">
                <Container>
                    <Row className="align-items-center">
                    {/* Left Column: Text content */}
                        <Col style={{color:"black"}} md={6} className="first-text">
                            <h2  style={{fontWeight: "450"}} className="display-4">Be the Leader in Giving <br/>That Everyone Talks About</h2>
                            <p style={{fontSize:"1.6rem"}} className="lead">Bunchful AI ensures your philanthropic impact isn’t just seen it’s celebrated. Qualify for the spotlight today.</p>
                            <div className="mt-4">
                                <a 
                                    href="https://sales-landing-page-bunchful.vercel.app/" 
                                    className="btn btn-primary btn-lg me-2" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    >
                                    Qualify Now
                                </a>
                            </div>
                        </Col>

                        {/* Right Column: Logo (or any image) */}
                        <Col md={6}>
                            <img style={{width: "70%", borderRadius: "20px"}} src={bunchfulSpotlightLogo} alt="Logo" className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className="separator" style={{height: "1px", backgroundColor: "#c2c2c2", width: "100%"}}></div>

            <div id="why-bunchful-ai"  className="single-section">
                <Container>
                    {/* Why Choose Bunchful AI for Marketing Impact */}
                    <h1 style={{textAlign: "center", paddingBottom: "4%", fontWeight: "400"}}>Why Bunchful AI?</h1>
                    <Row style={{backgroundColor:"#f2f2ff", borderRadius: "10px", padding: "2%"}} className="align-items-center">
                        <Col md={6} className="text-content">
                            <h2 className="section-title">Because giving isn’t just about impact; it’s about connection.</h2>
                            <p className="section-description">
                                Bunchful AI bridges your generosity with global change, showcasing your leadership while aligning your actions with meaningful results.                            </p>
                            <span className="cta-label">Lead with Purpose. Market with Impact.</span>
                        </Col>
                        <Col md={6} className="image-content d-flex justify-content-center align-items-center">
                            <img
                                src={globalCauses}
                                alt="Global Causes"
                                className="section-image img-fluid"
                                style={{ width: "80%", borderRadius: "8px" }}
                            />
                        </Col>
                    </Row>

                    {/* Separator Line */}
                    <div className="separator" style={{height: "1px", backgroundColor: "#c2c2c2", width: "100%", margin: "30px 0"}}></div>

                    {/* Amplify Your Impact: Compelling Storytelling */}
                    <Row  style={{backgroundColor:"#ffede8", borderRadius: "10px", padding: "2%"}}  className="align-items-center">
                        <Col md={6} className="image-content d-flex justify-content-center align-items-center">
                            <img
                                src={storytelling}
                                alt="Storytelling"
                                className="section-image img-fluid"
                                style={{ width: "80%", borderRadius: "8px" }}
                            />
                        </Col>
                        <Col md={6} className="text-content">
                            <h2 className="section-title">Clarity Meets Action:</h2>
                            <p className="section-description">
                                Bunchful AI doesn’t just amplify your giving—it provides clear, measurable insights, showing how your generosity directly contributes to global progress.                            </p>
                            <span className="cta-label">Start Amplifying Your Impact</span>
                        </Col>
                    </Row>

                    {/* Separator Line */}
                    <div className="separator" style={{height: "1px", backgroundColor: "#c2c2c2", width: "100%", margin: "30px 0"}}></div>

                    {/* Revolutionize CSR Management */}
                    <Row  style={{backgroundColor:"#f2e9fd", borderRadius: "10px", padding: "2%"}}  className="align-items-center">
                        <Col md={6} className="text-content">
                            <h2 className="section-title">Turn Impact Into Influence:</h2>
                            <p className="section-description">
                                With Bunchful AI, your philanthropic efforts inspire a ripple effect, influencing partners, stakeholders, and communities to join you in creating lasting change.                            </p>
                            <span className="cta-label">Revolutionize Your CSR</span>
                        </Col>
                        <Col md={6} className="image-content d-flex justify-content-center align-items-center">
                            <img
                                src= {csrManagement}
                                alt="CSR Management"
                                className="section-image img-fluid"
                                style={{width: "70%", borderRadius: "8px" }}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="separator" style={{ height: "1px", backgroundColor: "#c2c2c2", width: "100%" }}></div>


            <div id="why-bunchful-ai"  className="single-section">
                <Container>
                    {/* Why Choose Bunchful AI for Marketing Impact */}
                    <h1 style={{textAlign: "center", paddingBottom: "4%", fontWeight: "400"}}>How Bunchful AI Works?</h1>
                    <Row style={{backgroundColor:"#f2f2ff", borderRadius: "10px", padding: "2%"}} className="align-items-center">
                        <Col md={6} className="text-content">
                            <h2 className="section-title">We Tell Your Story:</h2>
                            <p className="section-description">
                                Your giving deserves recognition. Bunchful AI transforms your efforts into a compelling narrative that inspires others.                                                            
                            </p>
                            <span className="cta-label">Lead with Purpose. Market with Impact.</span>
                        </Col>
                        <Col md={6} className="image-content d-flex justify-content-center align-items-center">
                            <img
                                src={globalCauses}
                                alt="Global Causes"
                                className="section-image img-fluid"
                                style={{ width: "80%", borderRadius: "8px" }}
                            />
                        </Col>
                    </Row>

                    {/* Separator Line */}
                    <div className="separator" style={{height: "1px", backgroundColor: "#c2c2c2", width: "100%", margin: "30px 0"}}></div>

                    {/* Amplify Your Impact: Compelling Storytelling */}
                    <Row  style={{backgroundColor:"#ffede8", borderRadius: "10px", padding: "2%"}}  className="align-items-center">
                        <Col md={6} className="image-content d-flex justify-content-center align-items-center">
                            <img
                                src={storytelling}
                                alt="Storytelling"
                                className="section-image img-fluid"
                                style={{ width: "80%", borderRadius: "8px" }}
                            />
                        </Col>
                        <Col md={6} className="text-content">
                            <h2 className="section-title">Inspire a Movement:</h2>
                            <p className="section-description">
                            Leadership in giving means showing others the way. Bunchful AI helps your generosity ignite action and foster change.
                            </p>
                            
                            <span className="cta-label">Start Amplifying Your Impact</span>
                        </Col>
                    </Row>

                    {/* Separator Line */}
                    <div className="separator" style={{height: "1px", backgroundColor: "#c2c2c2", width: "100%", margin: "30px 0"}}></div>

                    {/* Revolutionize CSR Management */}
                    <Row  style={{backgroundColor:"#f2e9fd", borderRadius: "10px", padding: "2%"}}  className="align-items-center">
                        <Col md={6} className="text-content">
                            <h2 className="section-title">Turn Action into Impact:</h2>
                            <p className="section-description">
                            Bunchful AI ensures your generosity resonates far beyond the moment, reaching communities, stakeholders, and partners.
                            </p>
                           
                            <span className="cta-label">Revolutionize Your CSR</span>
                        </Col>
                        <Col md={6} className="image-content d-flex justify-content-center align-items-center">
                            <img
                                src= {csrManagement}
                                alt="CSR Management"
                                className="section-image img-fluid"
                                style={{width: "70%", borderRadius: "8px" }}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="separator" style={{ height: "1px", backgroundColor: "#c2c2c2", width: "100%" }}></div>


            <div id="why-bunchful-ai"  className="single-section">
                <Container>
                    {/* Why Choose Bunchful AI for Marketing Impact */}
                    <h1 style={{textAlign: "center", paddingBottom: "4%", fontWeight: "400"}}>Align Your Giving with Global Goals</h1>
                    <Row style={{backgroundColor:"#f2f2ff", borderRadius: "10px", padding: "2%"}} className="align-items-center">
                        <Row className="text-content">
                        
                            <p className="section-description">
                            Bunchful AI doesn’t just amplify your story—it aligns your giving with the United Nations Sustainable Development Goals (SDGs). These 17 global goals tackle challenges like poverty, education, and climate change, helping measure the impact of your generosity.
                            <br/><br/>Example: Donations to education align with SDG 4: Quality Education, while sustainability efforts support SDG 13: Climate Action. We help your giving connect to global progress. </p>                           
                        </Row>

                        <Row className="justify-content-center">
                            <img 
                                src={sdgImage} 
                                className="img-fluid" 
                                style={{width: "80%", height: "auto"}} 
                            />
                        
                        </Row>
                        <span className="cta-label">Lead with Purpose. Market with Impact.</span>

                    </Row> 
                                 
                    
                </Container>
            </div>

            <div className="separator" style={{ height: "1px", backgroundColor: "#c2c2c2", width: "100%" }}></div>

            {/* Benefits of Bunchful AI Section */}
            <div style={{ backgroundColor: "#fdf4f6", padding: "3% 0" }} id="benefits-of-bunchful-ai" className="single-section">
                <Container>
                    <h1 style={{ textAlign: "center", paddingBottom: "4%", fontWeight: "450", color: "#333", letterSpacing: "1px" }}>
                        Benefits of Bunchful AI
                    </h1>
                    <Row className="g-4 text-center">
                        <Col md={6} lg={4}>
                            <div
                                className="card h-100 shadow-sm"
                                style={{
                                    borderRadius: "10px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = "translateY(-10px)";
                                    e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = "translateY(0)";
                                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontWeight: "600", color: "#333" }}>Tailored Messaging</h5>
                                    <p className="card-text">
                                        Your generosity, presented in ways that resonate with your audience and stakeholders
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4}>
                            <div
                                className="card h-100 shadow-sm"
                                style={{
                                    borderRadius: "10px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = "translateY(-10px)";
                                    e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = "translateY(0)";
                                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontWeight: "600", color: "#333" }}>Global Recognition</h5>
                                    <p className="card-text">
                                        Your philanthropic leadership showcased as a model for others to follow and presented  in ways that resonate with your audience and stakeholders
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4}>
                            <div
                                className="card h-100 shadow-sm"
                                style={{
                                    borderRadius: "10px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = "translateY(-10px)";
                                    e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = "translateY(0)";
                                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontWeight: "600", color: "#333" }}>Enhanced Collaboration</h5>
                                    <p className="card-text">
                                        We help you connect with partners and organizations aligned with your goals to expand your impact
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4}>
                            <div
                                className="card h-100 shadow-sm"
                                style={{
                                    borderRadius: "10px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = "translateY(-10px)";
                                    e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = "translateY(0)";
                                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontWeight: "600", color: "#333" }}>Turn Your Generosity into a Movement</h5>
                                    <p className="card-text">
                                        Don’t just give—lead. Bunchful AI spreads your story to drive global impact and automatically matches them with relevant UN SDGs to ensure alignment with global goals.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4}>
                            <div
                                className="card h-100 shadow-sm"
                                style={{
                                    borderRadius: "10px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = "translateY(-10px)";
                                    e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = "translateY(0)";
                                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontWeight: "600", color: "#333" }}>Lead the Conversation on Generosity</h5>
                                    <p className="card-text">
                                        Bunchful AI takes your philanthropy to the next level-amplifying your impact and inspiring the world
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4}>
                            <div
                                className="card h-100 shadow-sm"
                                style={{
                                    borderRadius: "10px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = "translateY(-10px)";
                                    e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = "translateY(0)";
                                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontWeight: "600", color: "#333" }}>Your Philanthropy. Spotlighted. Amplified. Celebrated</h5>
                                    <p className="card-text">
                                    Bunchful AI turns your giving into a powerful story that inspires
                                    </p>
                                </div>
                            </div>
                        </Col>
                        {/* Add additional cards as needed */}
                    </Row>
                    <div className="text-center mt-4">
                        <a
                            href="https://sales-landing-page-bunchful.vercel.app/"
                            className="btn btn-primary btn-lg"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                backgroundColor: "#5050f3",
                                borderColor: "#5050f3",
                                color: "white",
                                padding: "12px 30px",
                                fontSize: "1.2rem",
                                letterSpacing: "0.5px",
                                borderRadius: "8px",
                                transition: "all 0.3s ease",
                            }}
                            onMouseOver={(e) => {
                                e.target.style.backgroundColor = "#333";
                                e.target.style.borderColor = "#333";
                            }}
                            onMouseOut={(e) => {
                                e.target.style.backgroundColor = "#5050f3";
                                e.target.style.borderColor = "#5050f3";
                            }}
                        >
                            Qualify Now
                        </a>
                    </div>
                </Container>
            </div>


            {/* Separator Line */}
            <div className="separator" style={{height: "1px", backgroundColor: "#c2c2c2", width: "100%"}}></div>

            {/* Features Section */}
            <div id="how-it-works" className="section-3">
                <Container>
                    <h2 className="text-center">How to Get Started with Bunchful AI?</h2>

                    {/* Step 1: Fill Out the Prequalification Form */}
                    <Row className="align-items-center mt-5">
                        <Col md={6}>
                            <h3>
                                Step 1: Provide your information along with company details on this <a 
                                    style={{
                                    color: "#5050f3", 
                                    textDecoration: "none", 
                                    transition: "color 0.3s ease-in-out"
                                    }} 
                                    href="https://sales-landing-page-bunchful.vercel.app/" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    onMouseOver={(e) => e.target.style.color = '#2020a8'}
                                    onMouseOut={(e) => e.target.style.color = '#5050f3'}
                                >
                                    Prequalification Form.
                                </a>
                            </h3>
                        </Col>
                        <Col md={6} className="d-flex justify-content-center align-items-center">
                            <img 
                                src={sdgImage} 
                                alt="Step 1" 
                                className="img-fluid" 
                                style={{ width: "50%", height: "auto" }} 
                            />
                        </Col>
                    </Row>
                    <div className="separator" style={{height: "1px", backgroundColor: "#c2c2c2", width: "100%"}}></div>

                    {/* Step 2: Enter Your Contributions */}
                    <Row className="align-items-center mt-5">
                        <Col md={6}>
                            <h3>Step 2: Enter Your Philanthropic Activities</h3>
                        </Col>
                        <Col md={6} className="d-flex justify-content-center align-items-center">
                            <img 
                                src={partnershipImage} 
                                alt="Step 2" 
                                className="img-fluid" 
                                style={{ width: "50%", height: "auto" }} 
                            />
                        </Col>
                    </Row>
                    <div className="separator" style={{height: "1px", backgroundColor: "#c2c2c2", width: "100%"}}></div>

                    {/* Step 3: Automatic SDG Matching */}
                    <Row className="align-items-center mt-5">
                        <Col md={6}>
                            <h3>Step 3: Follow our guide to match your giving to the global goal it supports.
                            </h3>
                        </Col>
                        <Col md={6}>
                            <img src={step3} alt="Step 3" className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className="separator" style={{ height: "1px", backgroundColor: "#c2c2c2", width: "100%" }}></div>

            {/* CTA Section */}
            <div id="spotlight-program" className="cta-section text-center">
                <Container style={{paddingTop: "5%", paddingBottom: "5%"}}>
                    <h3 style={{fontSize: "2rem"}}>Why Wait? Start Leading in Giving Today
                    </h3>
                    <p>
                        The world needs leaders like you—leaders who aren’t afraid to take bold action and show what’s possible through generosity. With Bunchful AI, you’re not just giving; you’re transforming lives, inspiring change, and building a legacy.
                        Qualify for your spotlight today.
                    </p>
                    <div className="mt-4">
                        <a 
                            href="https://sales-landing-page-bunchful.vercel.app/" 
                            className="btn btn-lg" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            >
                            Apply Now to See If You Qualify
                        </a>
                    </div>
                </Container>
            </div>

            <div className="separator" style={{ height: "1px", backgroundColor: "#c2c2c2", width: "100%" }}></div>

            {/* Footer Section */}
            <Footer/>
        </div>
    );
};

export default Homepage;