import React from "react";
import "./Notfound.css";
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { Helmet } from 'react-helmet';

const NotFound = () => {
    return (
        <div className="not-found">
            <div>
                <Helmet>
                    <title>Page not found - Bunchful AI | Official Site</title>
                </Helmet>
            </div>

            <Header />

            <div className="section-1">
                <Container>
                    <Row className="justify-content-center text-center">
                        <Col md={8}>
                            <p className="not-found-text">
                                This page is out spreading kindness! <br />
                                Let's find our way back to generosity.
                            </p>
                            <div className="return-container">
                                <a href="/" className="return-button">Return to Homepage</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer />
        </div>
    );
}

export default NotFound;
